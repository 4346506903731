import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/da/analyse-konsulent",
  "Event Tracking": "/da/event-sporing"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/event-tracking"
);

const EventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Event Tracking i Google Analytics [Setup Guide ${new Date().getFullYear()}]`}
        description="Guide til at konfigurere event tracking for moderne Google Analytics sammen med Google Tag Manager. Tutorial med skærmbilleder og forklaringer fra certificeret ekspert."
        lang="da"
        canonical="/da/event-sporing"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2024-06-11T04:32:43.188Z"
        dateModified="2024-06-11T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="event data graf fra Google Analytics event rapportering"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Event Tracking</H>
        <p>
          Konfigurering af Google Analytics event tracking er afgørende for dataanalyse, fordi det bruges til at spore brugerinteraktioner. Analyse af sidevisninger, sessioner og besøgende mangler kontekst om en hjemmesidebesøgendes engagement.
        </p>{" "}
        <p>
          Dette løses ved at indsamle yderligere data fra brugerdefinerede events, der fanger brugerinteraktioner, såsom linkklik for eksempel.
        </p>
        <p>
          Events repræsenterer brugerinteraktioner eller forekomster.
          <br />
          Normalt står de vigtigste interaktioner i relation til hjemmesidens mål.
          <br /> Derfor opsætter vi brugerdefineret event tracking for at bruge det som en performanceindikator for at se, om vores mål bliver opnået.
        </p>
        <p>
          Events kan grupperes i <strong>hårde konverteringer</strong> og <strong>bløde konverteringer</strong> for at tage højde for deres relation til hjemmesidens overordnede mål.
        </p>
        <p>
          Da events ofte er grundlaget for at overvåge konverteringer i en e-handels- eller remarketingkontekst, kaldes det nogle gange konverteringssporing.
          <br />Jeg skelner mellem konverteringssporing og sporing af en event, da en konvertering også kan være en sidevisning eller en <b>passiv</b> forekomst, som for eksempel at bruge en vis mængde tid på en landing page.
        </p>
        <p>
          For at illustrere hvad event tracking betyder, vil jeg vise dig, hvordan du opsætter <strong>kliksporing</strong> på en submit-knap. Derefter burde du være i stand til at opsætte dine egne unikke klikevents på ethvert element du ønsker - så lad os komme i gang!
        </p>
        <H as="h2">Hvad er event tracking i Google Analytics?</H>
        <p>
          En event kan enten være en <strong>aktiv brugerinteraktion</strong> eller en <strong>passiv forekomst</strong>.
          At spore dem betyder at overvåge eller tælle forekomsterne og sætte dem i relation til brugere, besøg og andre dimensioner til senere analyse.
        </p>
        <p>Da du er fri til at konfigurere events, som du vil, kan de antage mange forskellige former.</p>
        <H as="h3">Eksempler</H>
        <ul>
          <li>tilføje et produkt til en indkøbskurv</li>
          <li>tilmelde sig en e-mail liste</li>
          <li>scrolle ned til bunden af en artikel</li>
          <li>klikke play på en video</li>
          <li>bruger login</li>
          <li>klikke på et billede for at zoome</li>
          <li>indsende en kontaktformular</li>
          <li>klik på et udgående link</li>
          <li>udskrive en artikel</li>
          <li>downloade en fil</li>
        </ul>
        <p>
          Som du kan se, er events normalt bundet til noget brugeren <em>gør</em>, baseret på overvågning af...
        </p>
        <ul>
          <li>museklik</li>
          <li>musebevægelser</li>
          <li>scrolling</li>
          <li>browsernavigation</li>
          <li>tastaturinput</li>
        </ul>
        <p>
          Events kan også være <em>passive</em>, uden at brugeren gør noget aktivt.
          <br />
          For eksempel når brugeren bruger en vis <strong>mængde tid på en side</strong> eller når en formular returnerer en <strong>fejl</strong>. Også når et <strong>chatbot-vindue</strong> åbner for at tale med besøgende, er der ingen aktivitet fra brugeren.
        </p>
        <p>Selvom en <b>sidevisning</b> teknisk set også er en interaktion, tæller den mest anvendte Universal Google Analytics en sidevisning ikke som en event. Dog bruger den nye Google Analytics App + Web (i øjeblikket i Beta) en anden datamodel, som også sporer sidebesøg som events.</p>
        <H as="h2">Eventtyper</H>
        <p>Vi kan skelne mellem to typer events:</p>
        <H as="h6">Aktive events (med brugerinteraktion)</H>
        <ul>
          <li>museklik, scroll tracking og musebevægelser</li>
          <li>berøring af skærmen</li>
          <li>tastaturinput</li>
        </ul>
        <H as="h6">Passive events</H>
        <ul>
          <li>opnå en foruddefineret tidsgrænse</li>
          <li>Serverresponser</li>
          <li>Programmatisk validering</li>
          <li>Tredjepartsscript eksekvering</li>
        </ul>
        <p>
          Bemærk, at ved at spore aktive og passive events, kan du rekonstruere en stor del af kunderejsen.
          <br />
          Sådanne data giver dig mulighed for at drage konklusioner om brugeroplevelsen fra start til slut. Hvis du finder nogen fejl i rejsen, kan du nu tage skridt til at reducere de negative oplevelser.
        </p>
        <H as="h2">Struktur af Google Analytics Events</H>
        <p>
          Hver event i Google Analytics indeholder nogle eventfelter, eller stykker information om eventen, som vi kan bruge til at beskrive forekomsten:
        </p>
        <ul>
          <li>
            <strong>event category</strong> - for at kategorisere events i grupper. I konteksten af en kontaktformular, for
            eksempel, ville du ønske at gruppere alle events i en eventkategori <em>kontaktformular</em>
          </li>
          <li>
            <strong>event action</strong> - for at beskrive interaktionen, f.eks. <em>formularindsendelse</em>
          </li>
          <li>
            <strong>event label</strong> - for at tilføje yderligere information om interaktionen. For eksempel, hvis
            brugeren sender <em>adressen på deres hjemmeside</em> sammen med formularen, kan du bruge det til at skelne
            mellem de modtagne formularer
          </li>
          <li>
            <strong>event value</strong> - (<em>valgfri</em>) for at tilføje en numerisk værdi til informationen. For
            eksempel, hvis gennemsnitligt hver 25. kontakt fører til en 100$ konvertering, kunne vi tildele en værdi på <em>4</em>{" "}
            til hver event.
          </li>
          <li>
            <strong>event non-interaction</strong> - for at skelne mellem en aktiv eller passiv brugerinteraktion.{" "}
            <br />
            Som standard er det sat til <code>false</code>, hvilket betyder, at eventen tæller som aktiv.{" "}
            <strong>Vigtigt:</strong> Aktive events påvirker beregningen af en sides <b><Link to="/da/wiki-analytics/afvisningsprocent">bounce rate</Link></b>, ved
            at sænke den. Så hvis du nogensinde har problemer med en urealistisk lav bounce rate, er chancerne, at nogle events'
            brugerinteraktion er sat til <code>true</code> ved en fejl.
          </li>
        </ul>
        <H as="h2">Implementer Google Analytics event tracking</H>
        <p>Der er to mulige måder at implementere brugerdefineret event tracking på:</p>
        <ol>
          <li>
            <strong>Implementer det</strong> med javascript snippets på alle relevante HTML elementer sammen
            med en onclick event handler - <strong>Jeg anbefaler ikke</strong> denne tilgang, da den er fejlbehæftet
            og ikke skalerbar. Hvis du alligevel vil gøre dette, kan du finde kodeeksempler i{" "}
            <strong>Googles event tracking dokumentation</strong>:
          </li>
        </ol>
        <ul>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
              analytics.js event dokumentation (legacy)
            </a>
          </li>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
              gtag.js event dokumentation
            </a>
          </li>
        </ul>
        <ol start="2">
          <li>
            <strong>Opsætning med Google Tag Manager</strong> eller et andet <Link to="/da/tag-styring">tag management system</Link> efter
            eget valg. <br />
            Nemhed og fleksibilitet ved implementering af ændringer, samt skalerbarhed og nem integration med
            tredjepartstjenester gør det til den foretrukne implementeringsmetode.
          </li>
        </ol>
        <p>
          Jeg vil forklare, hvordan man sporer events med Google Tag Manager, da det er det mest udbredte tag
          management system, som alle kan få adgang til.
        </p><p>Hvis du vil lære mere om GTM, som hvordan man opsætter det og sporer ting som udgående links og downloads, læs min <Link to="/da/google-tag-manager-opsætning">Google Tag Manager tutorial</Link>.</p>
        <H as="h2">Guide til at spore events med Google Tag Manager</H>
        <p>
          Opsætning af brugerdefineret event tracking kan opdeles i at oprette en <strong>trigger</strong> for eventen, samt
          oprette en <strong>event tag</strong>, som udfylder eventegenskaberne (
          <em>kategori, handling, etiket</em>, osv.). <br />
          Værdierne for eventegenskaberne kan enten tildeles statisk eller dynamisk - hvor sidstnævnte foretrækkes for at skalere konfigurationen over større sider.
        </p>
        <p>
          Som et eksempel vil vi implementere Google Analytics tracking for et knapklik.{" "}
          <strong>Kliksporing</strong> er den mest anvendte form for brugeradfærdssporing, så eksemplet burde være
          anvendeligt i mange andre tilfælde.
        </p>
        <p>På dette tidspunkt antager jeg, at du allerede har oprettet et tracking-ID, og at trackingkoden blev implementeret på hele siden, da du <Link to="/da/google-analytics-opsætning">opsatte Google Analytics</Link> med GTM.</p>

        <H as="h3">Konfigurer event trigger i Google Tag Manager</H>
        <ul>
          <li>
            Først skal vi logge ind på vores <a href="https://tagmanager.google.com">Google Tag Manager container</a>{" "}
            og <strong>oprette en ny trigger</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
          alt="Opsæt ny trigger i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            Giv din trigger et <strong>navn</strong>, for eksempel <strong>Click - Button</strong>, og klik på{" "}
            <strong>trigger konfiguration</strong> feltet for at vælge en trigger type. Du vil se en liste over trigger
            typer glide ind fra højre.
          </li>
          <li>
            Vælg <strong>Alle Elementer</strong> som typen og vælg, at{" "}
            <strong>triggeren kun udløses på nogle klik</strong>.
          </li>
          <li>
            Derefter skal du i den første dropdown vælge <strong>Click Element</strong>. Hvis det ikke er angivet, skal du blot klikke på{" "}
            <strong>Choose built in variable…</strong> og aktivere det.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-variable.png"
          alt="Trigger variabel i Google Tag Manager skærmbillede"
          className="article-img"
        />
        <ul>
          <li>
            I den anden dropdown skal du vælge <strong>matches CSS selector</strong>.<br />
            Nu lad mig forklare, hvad vi gør:
            <br />
            Vi prøver at generere en klik-trigger for et specifikt HTML-element på din hjemmeside - det element, du vil
            spore klik på. I mit eksempel er det en <strong>knap</strong>, men det kunne også være et{" "}
            <strong>billede</strong> eller en <code>&lt;div&gt;</code> tag, eller ethvert andet HTML-tag - så spørgsmålet er: Hvordan
            fortæller vi Google Tag Manager (GTM), hvilket element der skal overvåges?
            <br />
            Svaret er: <strong>CSS selektorer</strong> - Ved du ikke hvad det er? Det er blot en programmæssig måde
            at specificere HTML-elementer på siden, og jeg vil vise dig en måde at få CSS-selektoren for ethvert element
            med blot få museklik.
          </li>
          <li>
            I Googles Chrome-browser skal du <strong>højreklikke</strong> på ethvert element, du gerne vil spore klik på, og
            vælge <strong>inspicér</strong>. Chrome Developer Tools åbner, og som standard vil HTML-elementet
            være forvalgt.
          </li>
          <li>
            Derefter skal du blot <strong>højreklikke</strong> på elementet og vælge <strong>Kopiér</strong> &gt;{" "}
            <strong>Kopiér JS-sti</strong> (undgå <em>kopiér selektor</em> - det returnerer fejlbehæftede selektorer fra tid til anden).
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
            alt="Kopiér CSS selektor til knapkliksporing"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Hvis du indsætter indholdet af din udklipsholder i en teksteditor, vil du se, at du har kopieret noget som:
            <br />
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
            <br />
            <br />
            Vi ønsker at tage den del, der er inde i parenteserne, uden “”. Så denne del:
            <br />
            <br />
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>Dette er CSS selektoren</strong>, som vi skal indsætte i det sidste felt i vores triggerkonfiguration i GTM (<em>se nedenfor</em>).
            <br />
            <strong>Kopier</strong> den værdi og indsæt den. Klik <strong>gem</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Event trigger konfiguration i Google Tag Manager skærmbillede"
          className="article-img"
        />
        <p>Bemærk, at du kunne gøre dette med ethvert element og opsætte enhver event-trigger i GTM, som du ønsker?</p>
        <p>
          Vi er ikke færdige endnu. Efter opsætning af triggerbetingelserne for vores event, skal vi konfigurere Google
          Analytics event-tagget for at sikre, at de rigtige data sendes til Google Analytics-ejendommen.
        </p>
        <H as="h3">Opret en brugerdefineret event-tag</H>
        <p>
          Nu hvor vi har en trigger, skal vi kombinere den med en faktisk event-tag. Du kan tænke på triggers og
          tags som <em>hvornår</em> og <em>hvad</em> i vores eksempel: <br /> Triggeren definerer <em>hvornår</em> vi vil
          spore en event (<em>når vores knap bliver klikket</em>), og nu vil vi definere en GA-event, der beskriver{" "}
          <em>hvad</em> vi gør: <em>Sende en event til GA inklusive alle de rigtige værdier for eventegenskaberne.</em>
        </p>
        <p>Lad os komme i gang.</p>
        <ul>
          <li>
            I Google Tag Manager går du til tags-menuen og <em>opretter en ny tag</em>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
          alt="Google Tag Manager opret brugerdefineret event-tag"
          className="article-img"
        />
        <ul>
          <li>
            Giv taggen et <strong>navn</strong> som <em>Button Event</em> og klik på{" "}
            <strong>tag konfiguration</strong>.
          </li>
          <li>
            Et panel fra højre side vil glide ind med en liste over mulige tagtyper at vælge imellem. Vælg{" "}
            <strong>Google Analytics - Universal Analytics</strong>.
          </li>
          <li>
            Tilbage i tag-konfigurationen skal du sætte <strong>track type</strong> til <strong>event</strong>. Nu vil du se flere felter, der vises for eventdata.
          </li>
          <li>
            Lad os tildele statiske værdier for <strong>event category</strong> og <strong>action</strong> og lade <strong>event label</strong> blive udfyldt dynamisk.
            <br />
            Kald <strong>category</strong> for eksempel <em>click events</em>, <strong>action</strong> er blot <em>click</em> og for <strong>label</strong> skal du klikke på Legoklodssymbolet ved siden af.
          </li>
          <li>
            Vi ønsker, at label altid indeholder den tekst, der er skrevet på det klikkede HTML-element, så i tilfælde af at vi sporer flere knapper, kan vi skelne mellem dem.
            <br />
            Efter at have klikket på Legoklodsen vil et panel glide ind for at <strong>vælge en variabel</strong>. Klik på <strong>built-ins</strong> i øverste højre hjørne, medmindre du allerede ser <strong>click text</strong> på listen over variabler (vælg det da blot <strong>og spring det følgende trin over</strong>).
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
          alt="indbygget variabel for event label i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            På listen over indbyggede variabler skal du aktivere <strong>click text</strong> og GTM vil tilføje den variabel i label-feltet i krøllede parenteser, sådan her: <code>{"{{Click Text}}"}</code> - Dobbelt krøllede parenteser er den interne måde i GTM at referere variabler på.
          </li>
          <li>
            Feltet <strong>value</strong> i tag-konfigurationen kan udfyldes med en værdi, som eventklikket måtte repræsentere. <br />
            I mit eksempel lader jeg feltet være <strong>tomt</strong>, fordi jeg ikke har nogen meningsfuld monetær værdi for knapklikket.
          </li>
          <li>
            Hvis eventen er <strong>interaktiv</strong>, vælg <strong>false</strong> for non-interaction hit. Hvis det er en <strong>passiv event</strong>, vælg <strong>true</strong>.
          </li>
          <li>
            I variablen <strong>Google Analytics settings</strong> skal du vælge den variabel, du konfigurerede, da du <a href="/da/google-analytics-opsætning">opsatte Google Analytics</a>.
          </li>
          <li>
            Hvis du ikke har konfigureret en <strong>settings variable</strong>, skal du blot sætte et flueben ved <em>Enable overriding settings in this tag</em> og tilføje tracking-ID'et (f.eks. <em>UA-12345678-91</em>) fra din <Link to="/da/google-analytics-opsætning">Google Analytics tracking code</Link>.
          </li>
          <li>
            Til sidst skal du scrolle ned til <strong>triggering</strong> konfigurationen, <strong>klikke på det</strong> og vælge den trigger, vi konfigurerede kort før: <em>Click - Button</em>
          </li>
        </ul>
        <p>Hele event-tag-konfigurationen på én gang:</p>
        <ImgContainerFixed width="371px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
            alt="GTM event tag konfiguration"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Klik nu <strong>save</strong> i øverste højre hjørne, og du er færdig med at opsætte GA-eventen.
          </li>
        </ul>
        <p>Fantastisk! Det næste skridt er at dobbelttjekke, om vores onclick tracking-opsætning fungerer.</p>
        <H as="h2">Fejlfinding af events</H>
        <p>
          For at fejlsøge opsætningen skal du gå ind i <strong>preview mode</strong> fra Google Tag Manager
          oversigten:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-preview.png"
          alt="Google Tag Manager preview mode"
          className="article-img"
        />
        <br />
        <p>
          Derefter besøger vi siden, der indeholder submit-knappen. På grund af Google Tag Manager preview
          mode har vi visse <Link to="/da/wiki-analytics/cookies">cookies</Link> sat i vores browser, der vil åbne et fejlfindingsvindue i bunden af siden:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="google tag manager debugger for events"
          className="article-img"
        />
        <br />
        <p>
          Hvis du nu <strong>klikker på knappen</strong> for at teste event-tracking, bør du se et{" "}
          <strong>klik</strong> dukke op i venstre oversigtspanel, og hovedfejlfindingsvinduet bør vise, at vores
          event blev udløst:
        </p>
        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="google tag manager debugger kliksporing"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Hvis du klikker på <strong>button event</strong>, kan du verificere de event-felter, der blev sendt sammen med
          event-hittet:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="google tag manager debugger event felter"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Ser du, hvordan <strong>event label</strong> automatisk er blevet udfyldt med “OK”? Det er fordi vi brugte
          den indbyggede variabel <strong>click text</strong> i Google Tag Manager, som altid indeholder teksten på det
          klikkede element.
        </p>
        <p>Vigtigere er det, at hvis du scroller længere ned, kan du verificere reglerne for event-triggeren:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="google tag manager event trigger regler"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          <strong>TIP</strong>: For at verificere, at din Google Analytics-konto har modtaget alle data, kan vi
          besøge <strong>realtidsrapporten for events</strong> i Google Analytics. Hvis der er gået et par minutter siden
          sidste knapklik, bør <strong>visning for de seneste 30 min</strong> hjælpe.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-realtime-report.png"
          alt="google analytics realtidsrapport for events"
          className="article-img"
        />
        <H as="h3">CSS-selektor validering</H>
        <p>
          Hvis debuggeren ikke registrerede dine knapklik og ikke viste nogen klik-event, er der sandsynligvis noget galt med <strong>CSS-selektoren</strong>.<br />
          <strong>TIP:</strong> Du kan åbne <strong>chrome developer tools</strong> på siden med det element, du
          vil spore ved enten at trykke <strong>F12</strong> eller <strong>højreklikke</strong> &gt;{" "}
          <strong>inspicér</strong> og besøge <strong>elements</strong> fanen. Hvis du nu trykker{" "}
          <strong>CTRL+F</strong> eller <strong>CMD+F</strong> på Mac for at søge efter et element, kan du blot{" "}
          <strong>indsætte din CSS-selektor i søgefeltet</strong>, og udviklerværktøjer vil automatisk springe til elementet:
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="google developer tools find element med css selektor"
            className="article-img"
          />
        </ImgContainerFixed>
        <p>
          På denne måde kan du verificere, om din CSS-selektor virker. Hvis udviklerværktøjerne ikke springer til noget element i koden, er der noget galt med selektoren. Der findes også <strong>browserudvidelser</strong>, der kan hjælpe dig med at få den rigtige selektor.
        </p>
        <p>
          Efter at have fejlrettet og rettet selektorer, burde vi endelig have kliksporing kørende og kan gå tilbage til
          Google Tag Manager for at <strong>forlade preview mode</strong> og <strong>indsende ændringerne</strong>.
        </p>
        <p>
          Tillykke indtil videre! Du har konfigureret event-overvågning for at klikke på en knap baseret på en CSS-selektor!
        </p>
        <p>
          Med opsætningen, der nu fungerer, kan du{" "}
          <Link to="/da/google-analytics-opsætning#how-to-set-up-goals-in-google-analytics">opsætte event-mål</Link> i
          GA! Google Analytics mål er en nyttig funktion, da du kan opdele rapporter og se, hvor mange konverteringer
          der er sket for et givet undersegment.
        </p>
        <p>
          For at opsætte det skal du blot definere et brugerdefineret Google Analytics-mål med kategori-, handlings- og labelfelterne, der matcher din event, og så vil det blive talt som et event-mål.
        </p>
        <H as="h2">Fordele &amp; Ulemper ved sporing med CSS-selektorer</H>
        <p>
          Det er vigtigt at nævne, at opsætning af event-sporing baseret på CSS-selektorer ikke er den mest robuste metode.
        </p>
        <p>
          Hele event-opsætningen vil bryde sammen, hvis CSS-selektoren ikke virker. Det er en <em>kritisk afhængighed</em>,
          hvilket repræsenterer en svaghed i denne tilgang til opsætning.
        </p>
        <p>Men hvorfor skulle selektoren nogensinde stoppe med at virke?</p>
        <p>
          Fordi jo større hjemmesiden er, jo flere ændringer sker der på en side, og jo flere mennesker er involveret -
          muligvis samtidig i forskellige teams.
          <br /> Så snart en ændring påvirker strukturen af vores selektor, vil den stoppe med at virke.
        </p>
        <p>
          En frontend-udvikler kan godt tilføje nogle ændringer til siden en dag i fremtiden, hvilket kan få{" "}
          selektoren til at gå i stykker. Derfor ville det være mere robust at tilføje en <strong>onclick event handler</strong> til
          elementet, som sender alle event-egenskaber til en{" "}
          <strong>
            <Link to="/da/data-lag">data layer</Link>
          </strong>
          . Dette er den anbefalede tilgang for vigtige KPI'er og for store organisationer, hvor ændringer ikke bliver overset.
        </p>
        <p>Avancerede webanalyseimplementeringer, der integrerer databaser og tredjepartsdatakilder, bruger en <Link to="/da/data-lag">data layer til at spore events</Link>. Læs venligst den artikel for den nødvendige event-tracking kode.</p>
        <p>Brug af en data layer er mere sikker og pålidelig, men kræver en grundig implementering.</p>
        <p>I stedet for at stole på CSS-selektorer, bruger du event listeners for <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">browser events</a> som en trigger. GTM bruger også klik event listeners under overfladen, når du opsætter en klik-trigger, men når du baserer sporing på en data layer, kan du mere frit vælge, hvilke browser events der skal spores.</p>
        <p>Især for en single page app (SPA) uden sideloader mellem sidenavigation, kan en data layer bruges godt til at spore <b>virtuelle sidevisninger</b> som events og få dem til at tælle som almindelige sidevisninger i Google Analytics.</p>
        <p>
          For mindre hjemmesider er indsatsen for at implementere en data layer ikke altid berettiget. I sådanne tilfælde er event
          sporing baseret på CSS-selektorer en gyldig tilgang.
        </p>
        <p>
          <strong>Fordelen</strong> ved at bruge CSS-selektorer er klart <strong>letheden</strong> ved implementeringen og hvor{" "}
          <strong>hurtigt</strong> du kan begynde at indsamle data. Dette kan også være nyttigt, hvis en data layer-implementering
          kan tage lidt tid på grund af mangel på ressourcer, fordi du måske allerede kan indsamle nogle af
          dataene blot med CSS-selektorer.
        </p>
        <p>
          <strong>Ulempen</strong> er, at denne metode er næsten <strong>udsat for at gå i stykker</strong> en dag,
          afhængigt af hvor mange ændringer der bliver implementeret, og hvor mange teams der arbejder på hjemmesiden.
        </p>
        <p>
          Men hvis du opsætter websporing for din egen hjemmeside og har kontrol over
          ændringerne, er du velkommen til at følge denne metode. Vær blot opmærksom på, at ændringer i sidens HTML-struktur kan
          bryde CSS-selektoren.{" "}
        </p>
        <p>
          Men virksomheder med et budget og høje krav til datakvalitet bør vælge en data layer som fundamentet for deres event tracking-opsætning.
        </p>
        <H as="h2">Eventrapporter i Google Analytics</H>
        <p>
          Nu hvor vi har opsat sporing for brugerinteraktioner, skal vi sørge for, at vi følger med i mængden af
          events, der sendes til Google Analytics-kontoen.{" "}
        </p>
        <p>
          Den bedste måde at gøre dette på er enten med den indbyggede <strong>eventrapport</strong>, med en{" "}
          <strong>brugerdefineret rapport</strong> eller et Google Analytics <strong>dashboard</strong>.
        </p>
        <p>
          I <strong>Google Analytics</strong> kan du gå til <strong>Adfærd</strong> &gt; <strong>Begivenheder</strong>{" "}
          &gt; <strong>Topbegivenheder</strong> for at finde de eventdata, du har sporet:
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report.png"
          alt="Google Analytics event tracking rapport oversigt"
          className="article-img"
        />
        <br />
        <p>
          Da der ikke er noget standard <b>eventnavn</b> tilgængeligt i GA, vil du først se dine eventkategorier. Du kan også vælge den primære dimension til at være <strong>eventhandling</strong> eller{" "}
          <strong>event label</strong> for at dykke dybere ned i rapporten.
        </p>
        <p>
          Bemærk, at event-rapporten giver os en kolonne med <strong>Gennemsnitlig værdi</strong>, hvilket er praktisk, hvis vi har brug for
          den gennemsnitlige værdi af vores klik-events. Dette kan være relevant for en webapp, der beregner priser, hvor det
          endelige resultat spores med en event. På denne måde kan du nemt finde den gennemsnitlige værdi af en brugers
          beregninger.{" "}
        </p>
        <p>
          Andre almindelige eksempler på nyttige gennemsnitsværdier er f.eks. filterindstillinger for priser eller størrelser i en e-handelsbutik.{" "}
        </p>
        <p>
          Så husk, at en <strong>eventværdi</strong> måske virker urelateret i starten, men der kan godt være en
          relateret værdi at tildele til eventen alligevel, da det vil være nyttigt at tjekke gennemsnitsværdierne på tværs af
          alle events.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default EventTracking;
